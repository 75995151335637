.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fbfcff;
  max-width: 100%;
  max-height: 100%;
  flex: 1;
  overflow-y: auto;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-header::-webkit-scrollbar {
  display: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.adm-tab-bar-item-active {
  color: #7ac968 !important
}

video {
  transform: scaleX(-1);
}