.carousel {
  height: calc(100% - 24px) !important;
  width: 100% !important;
}

div.slick-list {
  height: 100% !important;
}

div.slick-track {
  display: flex !important;
  height: 100% !important;
}

div.slick-slide {
  height: 100% !important;
}

div.slick-slide > div {
  height: 100% !important;
}

div.slick-slide > div > div {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-content: flex-end !important;
}

.phone-number-input {
  width: 100% !important;
  height: 80px !important;
  font-size: 32px !important;
  margin-left: 0 !important;
}

.phone-dropdown {
  display: none !important;
}

.step_class div.ant-steps-item-title {
  font-size: 18px !important;
}

.step_class div.ant-steps-item-icon span.ant-steps-icon > span {
  font-size: 24px !important;
}

.flag-dropdown {
  display: none !important;
}

@keyframes blink-green {
  0% {border-color: #7ac968;}
  50% {border-color: transparent;}
  100% {border-color: #7ac968;}
}

.blink-red {
  animation: blink 1s linear infinite;
}

@keyframes blink-red {
  0% {border-color: red;}
  50% {border-color: white;}
  100% {border-color: red;}
}

.blink-green {
  animation: blink 1s linear infinite;
}